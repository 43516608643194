.layer{
    width: 100%;
    height: 100%;
    position: absolute;
}

.loginForm{
    margin: auto;
    max-width: 500px;
    border: solid 1px red;
}

.modal{
    font-size: 14px;
    padding-top: 100px !important;
}

.form-group.row{
    margin: 20px auto;
}
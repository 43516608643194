.cardsContainer{
    padding-top : 50px;
    padding-bottom : 50px;
    background-color: #0d1c32;
    background-blend-mode: color-dodge;
    background-image: url(./../../../public/assets/pattern.jpg);
    background-size: 220px;
    background-position: 0px 0px;
    /* animation: animatedBackground 100s linear infinite alternate; */
}

.card{
    margin: 10px;
    background-color: #FFC107 !important;
}

.card img{
    height: 212px;
}

.card-body{
    background-color: white;
}


/* @keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 100%;
    }
} */

.zoomEffect{
    -webkit-animation: zoomeffect 1000s linear infinite alternate;
    -moz-animation: zoomeffect 1000s linear infinite alternate;
    animation: zoomeffect 1000s linear infinite alternate;
 }

 /* .hueEffect{
    -webkit-animation: hueffect 100s linear infinite alternate;
    -moz-animation: hueffect 100s linear infinite alternate;
    animation: hueffect 100s linear infinite alternate;
 } */

@keyframes hueffect{    
    0%{
        background-color: #0d1c32;
    }
    50%{
        /* background-color: #0d321e; */
        background-color: #322a0d;
    }
    100%{
        background-color: #0d1c32;
    }
}

@keyframes zoomeffect{    
    0%{
        background-position: 0 0;
        /* background-size: 1500px; */
        background-size: 8000px;
        background-color: #0d1c32;
    }
    50%{
        background-position: -100% -100%;
        /* background-size: 2000px; */
        background-size: 12000px;
        background-color: #322a0d;
    }
    100%{
        background-position: 0 0;
        /* background-size: 1500px; */
        background-size: 8000px;
        background-color: #0d1c32;
    }
}
.marginTop10{margin-top: 10px;}
.marginBottom10{margin-bottom: 10px;}
.marginTop30{margin-top: 30px;}
.paddingTop10{padding-top: 10px;}
.paddingTop0{padding-top: 0px;}
.clearBoth{clear: both;}
.pl5{padding-left: 5px;}
.pl10{padding-left: 10px;}
.pl20{padding-left: 20px;}
.lightBlue{color: #919ec1;}
.white{color: white;}
.offWhite{color: #b1b1b1;}

.footerSection{
    background-color: #061130;
    color: white;
    padding: 20px 0px;
}

.seperator{
    border-top: 1px solid #556487;
    border-block-width: 1px;
    border-style: dashed;
    border-color: #3f4e6e;
}

.disclaimer{
    font-size: 11px;
    color: #889fd5;
    color: #556487;
    margin-top: 20px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.copyright{
    color: #666;
    font-size: 11px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* margin-top: 20px; */
    background-color: #00000063;
}

.footerIcons{
    color: grey;
    margin-bottom: 20px;
}

.icon{
    border: 3px solid #061130;
    border-radius: 100px;
    padding: 7px;
    padding-top: 10px;
    width: 50px;
    height: 50px;
    background-color: grey;
    color: #061130;
    font-weight: bold;
    box-shadow: 0px 0px 1px 2px grey;
}

.icon247{
    padding: 5px;
    padding-top: 10px;
}

.iconSecurePayments{
    font-weight: bold;
    font-size: 25px;
    padding: 10px;
    padding-top: 0px;
}

.iconDataProtection{
    font-weight: bold;
    font-size: 25px;
    padding: 10px;
    padding-top: 3px;
}
.header{
    background-color: #010137;
    border-bottom: 1px solid #012d53;
}

.dropdown-toggle::after{
    margin-top: 17px;
}

.logo{
    font-family: "ColinasRegular";
    color: white;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
}

.logo:hover{
    color: white !important;
}

.black, .black:hover{
    color: black !important;
}

.colinasRegular{
    font-family: "ColinasRegular";
}

@font-face {
    font-family: 'ColinasRegular';
    src: local('ColinasRegular'), url(./../../../public/assets/fonts/ColinasPersonalUseRegular-2OBe3.ttf) format('truetype');
}
.gameContainer{
    padding-top : 20px;
    padding-bottom : 20px;
    background-color: #0d321f;/* #0d1c32; */
    /* background-blend-mode: color-dodge;
    background-image: url(./../../../public/assets/pattern.jpg);
    background-size: 220px;
    background-position: 0px 0px; */
}

.gameContainer.fluid{
    padding: 0px;
    background-image: url(./../../public/assets/pattern.webp);
    background-size: 100px;
}

.gameContainer.canvasFluid{
    background-image: url(./../../public/assets/patternCanvasFluid.jpg);
    background-size: 500px;
}

.gameContainer.canvas{
    background-color: #0d1c32;
    background-image: url(./../../public/assets/patternFluid.jpg);
    background-size: 500px;
}

.gameContainer canvas{
    border: solid 1px #FFFFFF33;
}
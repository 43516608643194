.circle{
    border: solid black 3px;
    border-radius: 100px;
    width: 100px;
    height: 100px;
    text-align: center;
    padding-top: 22px;
    margin: auto;
    font-size: 30px;
}

.circleText{
    padding-left: 25px;
    padding-top: 5px;
    /* text-align: center; */
}